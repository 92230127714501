<template>
  <modal class="scanCodePayModel" :isShow="isShow">
    <div class="header">扫码付
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body">
        <div class="clearfix">
            <div class="left-box">
                <div class="money-info" v-if="data">
                    <div class="lable-txt">待支付金额：<b class="noney red">{{data?.payMoney}}</b></div>
                </div>
                <div class="tr-li">
                    请扫码或输入付款码：
                    <div class="from-input" style="width:262px">
                        <input type="text" ref="input" data-model='payCode' v-model="payCode" @keyup.enter="confirm()" />
                    </div>
                    <count-down class="timer" v-if="isPaySuo" @success="isPaySuo=false" :duration="60" isIcon unit="秒"></count-down>
                </div>
            </div>
            <div class="right-box">
                <div class="keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                </div>
            </div>
        </div>
        <div class="tip-box">
            {{errorTitle}}
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">
            <span v-show="isPaySuo"><i class="iconfont icon-suo"></i></span>取消
        </button>
        <button class="btn btn-border" @click="select()" >重新查询支付结果</button>
        <button class="btn btn-confirm" @click="confirm()">
            <span v-show="isPaySuo"><i class="iconfont icon-suo"></i></span>确认
        </button>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 

/**会员支付 */
export default {
    name:"scanCodePayModel",
    emits:["close","success","updateTime"],
    props:{
        isShow:Boolean,
        /**
         * {
         *  payMoney:待支付金额,
         * }
         */
        data:{
            typeof:Object,
            default:null,
        }
    },
    data(){
        return {
            payCode:"",//付款码
            /**是否付款中 锁 */
            isPaySuo:false,
            /**错误返回 */
            errorTitle:'',
            /**是否触发确定回车功能 */
            layer:false,
            /**付款记录id 用于支付查询 */
            Biz_ID:null,
        }
    },
    mounted(){
       this.$nextTick(()=> {
            this.isPaySuo=false
            this.payCode=''
            this.errorTitle=''
            initKeyBoard.call(this,this.$el,this.$refs.input);
            this.EmptyDatas();
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow){
                this.isPaySuo=false;
                this.payCode='';
                this.errorTitle='';
                this.layer=false;
                this.Biz_ID=null;
                this.EmptyDatas();
            }else{
                this.create();
            }
        },
        isPaySuo(newVal){//解锁
            if(!newVal && this._timePay){
                this.create();//清除定时器
            }
        }
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
            this.isCountDown=false;
        },
        hide(){
            if(!this.isPaySuo){
                this.$emit("close")
            }
        },
        /**查询支付结果 */
        select(){
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                User_ID:userInfo?.User_ID,  //用户id
                Biz_ID:this.Biz_ID, //付款记录id
                Operator_Name:userInfo?.Login_Name, //操作员
                Operate_EquipmentID:userInfo?.Site_Serial, //设备id
                Pos_StationName:userInfo?.Site_Name, //站点名称
            }
            this.$httpAES.post("Bestech.CloudPos.PayARCorpRequeryResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.errorTitle = d.ResponseHeader.ResultDesc;
                    if(d.ResponseHeader.ResultCode==4003){//付款失败  时间戳变更
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        this.isPaySuo=false;
                        this.timerCount=0
                        this.payCode=''
                        //更新时间戳
                        if(d.ResponseBody?.Corp_LastUpdateTime){
                            this.$emit("updateTime",d.ResponseBody.Corp_LastUpdateTime);
                        }
                    }else if(d.ResponseHeader.ResultCode==4001){//重新查询
                        this.timeoutPayment();
                    }else{
                        this.isPaySuo=false;
                    }
                }else{
                    this.isPaySuo=false;
                    let ResultDesc = this.data.Recharge_Type==0?"已付款,清账成功":'付款成功';
                    if(d.ResponseBody?.PrintsInfo){//打印
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                        },100);
                    }
                    this.$emit("updateTime",d.ResponseBody.Corp_LastUpdateTime);
                    this.$alert(d.ResponseHeader.ResultDesc||ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.$emit("success");
                        }
                    });
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**确定和回车事件 */
        confirm(){
            if(this.isPaySuo){//支付锁 不能在进行支付预览
                return;
            }
            if(!this.payCode){
                this.$message.error('付款码不能为空');
                return;
            }
            if(this.data.payMoney==0){
                this.$message.error('单笔订单额度受限，建议使用其他付款方式');
                return;
            }
            if(this.layer){//支付请求中
                return ;
            }
            this.layer=true;
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
           
            let userInfo= this.$auth.getUserInfo();
            let param={
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                Pay_ID:this.data?.Pay_ID,//支付方式id
                PaymentType:801010301,//聚合支付
                Qr_Code:this.payCode,//支付方式二维码
                Corp_SumMoney:this.data?.money,//总金额
                Pay_Money:this.data.payMoney,//支付金额
                Pay_Type:this.data.payType,//0：部分清账 1：全部清账
                InvoiceStatus:this.data.InvoiceStatus,//0 未开票  1已开票
                Remark:this.data.remark,
                Pos_StationName:userInfo?.Site_Name,//站点名称
                Operate_EquipmentID:userInfo?.Site_Serial, //设备id
                Recharge_Type:this.data.Recharge_Type,//0：来款清账 1：预付款充值
                LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                Corp_ConsumeIDList:this.data.Corp_ConsumeIDList,//明细主键List
            }
            console.log(param)
            this.$httpAES.requestConfig({
                timeout:8*1000//设置8秒钟 超时时间
            }).post("Bestech.CloudPos.PayARCorpPreview",param).then((d)=>{
                loading.close();
                this.layer=false;
                setTimeout(() => {
                    this.$refs.input.focus();
                },100);
                //更新时间戳
                if(d.ResponseBody?.Corp_LastUpdateTime){
                    this.$emit("updateTime",d.ResponseBody?.Corp_LastUpdateTime);
                }
                if(d.ResponseHeader.ResultCode!=0){
                    let _error = d.ResponseHeader.ResultDesc
                    if(d.ResponseHeader.ResultDesc=='\r\n不支持的支付方式：,类型：0'){
                        _error ='该门店不支持聚合支付,请去后台设置相关功能'
                    }
                    if(d.ResponseHeader.ResultCode==4001 || d.ResponseHeader.ResultCode==3002){//付款中
                        if(d.ResponseHeader.ResultCode==4001){
                            if(d.ResponseHeader.ResultDesc.indexOf("密码")>=0 && this.$cacheData.globalVariable?.GlobalSysSetting?.SilverStone_SsPay_NeedPwdVoice){//语音播报 请输入支付密码
                                this.$webBrowser.tts("请输入支付密码");
                            }
                        }
                        
                        this.isPaySuo=true;
                        this.Biz_ID=d.ResponseBody?.Biz_ID;
                        this.timeoutPayment();
                    }else if(d.ResponseHeader.ResultCode==4003){//付款失败  时间戳变更
                        this.isPaySuo=false;
                        this.payCode=''
                    }
                    this.errorTitle=_error
                    this.$message.error(_error);
                }else{
                    if(this.data.Recharge_Type==0){
                        this.$message.success("清账成功");
                    }else{
                        this.$message.success("付款成功");
                    }
                    
                    this.isPaySuo=false;
                    this.$emit("success");
                    if(d.ResponseBody?.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    if(d.ResponseBody?.PrintsInfo){//打印
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                        },100);
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.isCountDown=false;
                this.$message.error('付款失败：'+e.message);
                console.log('付款失败：',e);
            })
        },
        /**定时查看结果 */
        timeoutPayment(){
            if(this._timePay) clearTimeout(this._timePay);
            if(this.isPaySuo){
                this._timePay=setTimeout(()=>{
                    if(this.isPaySuo){
                        this.select();
                    }
                },5000);
            }
        },
        create(){
            if(this._timePay) clearTimeout(this._timePay);
        }
    }
}
</script>

<style lang="sass">
@import "./scanCodePayModel.scss";
</style>