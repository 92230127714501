<template>
    <modal class="rechargePayModel" :isShow="isShow">
        <div class="header">预付款</div>
        <div class="modal-body">
            <div class="title">账户信息</div>
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">应收账户：</span>
                    <div class="from-input">{{data?.Corp_Name}}</div>
                </div>
            </div>
            <div class="title">付款信息</div>
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">付款方式：</span>
                    <select-tree class="from-input" ref="payTree" v-model="Pay_ID" placeholder="请选择付款方式" 
                        :isMultiple="false" :data="treePayTypes" 
                        searchPlaceholder="编号名称模糊检索"
                        is-search
                        :filter-node-method="filterNode"
                        title="付款方式选择" />
                </div>
                <div class="li-flex">
                    <span class="lable-text">实收金额：</span>
                    <div class="from-input"><input-pattern v-model="payMoney" pattern="number" :min="0" :dplaces="2"/></div>
                </div>
                <div class="li-flex" >
                    <span class="lable-text">是否开票：</span>
                    <div class="from-input">
                        <el-radio-group class="part-box" v-model="invoiceStatus" >      
                            <el-radio :label="0" size="large">未开票</el-radio>
                            <el-radio :label="1" size="large">已开票</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">备注：</span>
                    <div class="from-input"><input type="text" v-model="remark" maxlength="25"/></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()"> 确认</button>
        </div>
        <!-- 扫码付 -->
        <modal-load :isShow="scanCodePay" isUnload>
            <scan-code-pay-model :isShow="scanCodePay" @close="scanCodePay=false" 
                @updateTime="(time)=>{scanCodePayData.LastUpdated_Time=time;$emit('updateTime',time)}" 
                @success="scanCodePaySuccess()"
                :data="scanCodePayData"></scan-code-pay-model>
        </modal-load>
        <!--会员支付-->
        <modal-load :isShow="memberPayShow" isUnload>
            <member-payment-model :isShow="memberPayShow" @close="memberPayShow=false" 
                @updateTime="(time)=>{memberPayData.LastUpdated_Time=time;$emit('updateTime',time)}" 
                @success="memberPaySuccess()"
                :data="memberPayData"
            ></member-payment-model>
        </modal-load>
    </modal>
</template>

<script>

import scanCodePayModel from '../scanCodePayModel/scanCodePayModel'
import memberPaymentModel from '../memberPaymentModel/memberPaymentModel'
// 预付款
export default {
    name:'rechargePayModel',
    components:{
        scanCodePayModel,
        memberPaymentModel
    },
    emits:["close","confirm"],
    props:{
        isShow:Boolean,
        data:{
            typeof:Object,
            default:null,
        }
    },
    data(){
        return{
            /**付款方式数据 */
            payTypes:[],
            /**付款方式id */
            Pay_ID:"",
            //实收金额
            payMoney:"",
            //备注
            remark:'',
            /**0 未开票  1已开票 */
            invoiceStatus:0,
            /**扫码付款 */
            scanCodePay:false,
            /**扫码付款 传入的数据*/
            scanCodePayData:null,
            /**会员支付 */
            memberPayShow:false,
            /**会员支付 传入的数据*/
            memberPayData:null
        }
    },
    computed:{
        /**选择 付款方式下拉数据*/
        treePayTypes(){
            let tree=[];
            this.payTypes?.forEach(it=>{

                let children=[];
                it.PayTypes.forEach(it=>{//1现金支付 2 普通支付 801010301 聚合支付  801010401 正品CRM3.0会员支付
                    if(it.INTERFACE_TYPE=="1" || it.INTERFACE_TYPE=="2" || it.INTERFACE_TYPE=="801010301" || it.INTERFACE_TYPE=="801010401"){
                        children.push(Object.assign({
                            id:it.PAY_ID,
                            name:it.PAY_CODE+"-"+it.PAY_NAME,
                        },it))
                    } 
                })
                if(children.length>0){
                    tree.push({
                        id:"kind"+it.PAYKIND_ID,
                        name:it.PAYKIND_CODE+"-"+it.PAYKIND_NAME,
                        children:children
                    });
                }
                
            })
            return tree;
        },
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.Pay_ID="";
                this.payMoney="";
                this.remark="";
                this.invoiceStatus=0;
                this.scanCodePay=false;
                this.loadData();
            }
        }
    },
    mounted(){
        this.loadData();
    },
    
    methods:{
        hide(){
            this.$emit("close");
        },
        //加载数据
        loadData(){
            if(this.payTypes?.length>0){
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            /* 获取支付方式 */
            this.$cacheData.PayType().then((d)=>{
                loading.close();
                this.payTypes=d;
                console.log(d)
            }).catch(e=>{
                this.$alert("付款方式数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.PAY_CODE?.toLocaleUpperCase().includes(value) || data.PAY_NAME?.toLocaleUpperCase().includes(value);
        },
        /**点击确认 */
        confirm(){
            if(!this.Pay_ID){
                this.$message.warning("请先选择付款方式!");
                return;
            }

            if(this.payMoney==""){
                this.$message.warning("请先输入实收金额!");
                return;
            }

            if(!(this.payMoney>0)){
                this.$message.warning("实收金额必须大于0!");
                return;
            }
            let type=this.$refs.payTree?.val?.INTERFACE_TYPE;//付款方式类型
            if(type=="1" || type=="2"){//现金或普通支付方式
                this.rechargeARCorpMoney();
            }else if(type=="801010301"){//聚合支付
                this.scanCodePayData={
                    Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                    payMoney:this.payMoney,//待支付金额
                    Pay_ID:this.Pay_ID,//支付方式id
                    LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                    InvoiceStatus:this.invoiceStatus,//0 未开票  1已开票
                    remark:this.remark,//备注
                    Recharge_Type:1,//0：来款清账 1：预付款充值
                }
                this.scanCodePay=true;
            }else if(type=="801010401"){//正品CRM3.0会员支付
                this.memberPayData={
                    Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                    payMoney:this.payMoney,//待支付金额
                    Pay_ID:this.Pay_ID,//支付方式id
                    LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                    InvoiceStatus:this.invoiceStatus,//0 未开票  1已开票
                    remark:this.remark,//备注
                    Recharge_Type:1,//0：来款清账 1：预付款充值
                }
                this.memberPayShow=true;
            }
        },
        /**付款请求*/
        rechargeARCorpMoney(){
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.PayARCorpPreview",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                Pay_ID:this.Pay_ID,//支付方式id
                PaymentType:this.$refs.payTree?.val?.INTERFACE_TYPE,//付款方式类型
                Pay_Money:this.payMoney,//支付金额
                InvoiceStatus:this.invoiceStatus,//0 未开票  1已开票
                Remark:this.remark,
                Recharge_Type:1,//0：来款清账 1：预付款充值
                LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("预付款付款成功");
                    this.$emit('updateTime',d.ResponseBody?.Corp_LastUpdateTime)
                    this.$emit("confirm");
                    if(d.ResponseBody?.PrintsInfo){//打印
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                        },100);
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('预付款付款失败：'+e.message);
                console.log('预付款付款失败：',e);
            })
        },
        /**付款成功 */
        scanCodePaySuccess(){
            this.scanCodePay=false;
            this.$nextTick(()=>{
                this.$emit("confirm");
            })
        },
        /**会员支付成功 */
        memberPaySuccess(){
            this.memberPayShow=false;
            this.$nextTick(()=>{
                this.$emit("confirm");
            })
        }
    }
}
</script>

<style lang="scss">
@import './rechargePayModel.scss'
</style>